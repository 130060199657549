@import './variables.scss';

.CompanyContainer { 
    display:flex;
    justify-content: center;
    align-items: center;
    width: $companyBubbleSize;
    height: $companyBubbleSize;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-style: solid;
    border-width: 5px;
    border-radius: 50%;
    border-color: rgb(181, 181, 181);
    
    margin: $companyBubbleMarginHeight $companyBubbleMarginWidth;

    cursor: pointer;
}
.CompanyContainerLink {
    border-width: 0;
    border-radius: 50%;
    cursor: pointer;
}
.CompanyContainer img {
    max-width: 70%;
    max-height: 70%;
}