$defaultButtonColor: #b60d34;
$hoverButtonColor: #ac072d;
$pressingButtonColor: #9f072d;
$buttonUnavailableColor: #d2d2d2;

$headlineFont: "Optima Bold";
$headlineSize: 70pt;

$subheadlineFont: "Optima Bold";
$subheadline2Font: "Optima Regular";
$bodyTextAccentFont: "Open Sans SemiBold";
$textLinkFont: "Open Sans SemiBold";
$bodyTextFont: "Open Sans Regula";

$companyBubbleSize: 190px;
$companyBubbleMarginHeight: 10px; 
$companyBubbleMarginWidth: 20px; 

$avatarBubbleSize: 190px;
$avatarBubbleMarginHeight: 10px; 
$avatarBubbleMarginWidth: 20px; 

$max-width-for-responsivnes: 670px;