@import './variables.scss';

.CollapsibleMapContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 210px;
    right: 0;
}

//button
.ButtonContainer {
    transform-origin: bottom right;
    transform: rotate(270deg);
    margin-bottom: 250px;
}
.ButtonContainer button {
    border: none;
    background-color: black;
    padding: 20px 50px;
    
}
.ButtonContainer button h3 {
    margin: 0;

    text-transform: uppercase;
    color: whitesmoke;
}

//map
.MapContainer {
    width: 0px;
    height: 700px;
    overflow: hidden;
    transition: width 0.4s ease-out;
    background-color: #000000;
}
.MapContainer .Map {
    margin: 20px 20px;
    width: calc(85vw - 40px);
    height:  calc(700px - 40px);
}

.mapHeadline {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.mapButton {
    text-transform: uppercase;
    color: whitesmoke;
    font-family: "Optima Regular";
    font-size: 16pt;
    background-color: #b60d34;
    padding: 20px 50px;
    border: none;
    margin-top: 40px;
    cursor: pointer;
}
.mapContact {
    font-size: 14px;
    margin: 0;
}

@media only screen and (max-width: $max-width-for-responsivnes) {
    //button
    .ButtonContainer {
        margin-bottom: 150px;
    }
    .ButtonContainer button {
        padding: 5px 15px;
        white-space: nowrap;
    }
    
    //map
    .MapContainer {
        height: 98vh;
    }
    .MapContainer .Map {
        margin: 10px 10px;
        width: calc(85vw - 20px);
        height: calc(98vh - 20px);
    }
}
