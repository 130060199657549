@import './variables.scss';

.Container {
    margin: 0 auto;
    position: absolute;
    width: 100%;
    height: auto;
    background-color: black;
    display: flex;
    flex-direction: row;
    padding: 60px 0;
    margin-left: auto;
    margin-right: auto;

    .logo {
        align-items: center;
        align-content: center;
        justify-content: center;
        flex: 2;
        
        img {
            height: 50px;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        color: whitesmoke;
        text-align: left;
        width: 20%;
        height: auto;
        font-size: 12px;
        flex: 2;

        p {
            margin-top: 4px;
            margin-bottom: 4px;
        }
    }
}
.listFlex3 {
    flex: 3;
}
.listFlex1 {
    flex: 1;
}
@media only screen and (max-width: 1000px) {
    .listFlex3 {
        flex: 0.5 1 auto !important;
    }
    .listFlex1 {
        flex: 0.5 1 auto !important;
    }
}

@media only screen and (max-width: $max-width-for-responsivnes) {
    .Container {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .div {
        margin: 20px 0;
        width: 100%;
    }

    .list {
        width: 80% !important;
        font-size: 0.75rem !important;
    }
}
