@import './variables.scss';

@font-face {font-family: "Optima Regular";
    src: url("../fonts/OptimaRegular/3a861ecacbb23f33b2bb683e90c45e31.eot"); /* IE9*/
    src: url("../fonts/OptimaRegular/3a861ecacbb23f33b2bb683e90c45e31.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/OptimaRegular/3a861ecacbb23f33b2bb683e90c45e31.woff2") format("woff2"), /* chrome、firefox */
    /* url("../fonts/OptimaRegular/3a861ecacbb23f33b2bb683e90c45e31.woff") format("woff"),  chrome、firefox */
    url("../fonts/OptimaRegular/3a861ecacbb23f33b2bb683e90c45e31.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    /* url("../fonts/OptimaRegular/3a861ecacbb23f33b2bb683e90c45e31.svg#Optima nova LT Regular") format("svg");  iOS 4.1- */
}

.ButtonContainerCenter {
    display: flex;
    width: 100%;
    justify-content: center;
}

@mixin defaultButton {
    text-transform: uppercase;
    color: whitesmoke;
    font-family: 'Optima Regular';
    font-size: 16pt;

    background-color: $defaultButtonColor;
    padding: 20px 50px;
    border: none;
    margin-top: 40px;

    cursor: pointer;
}

.DefaultButton {
    @include defaultButton();
}

.DefaultButton:hover {
    background-color: $hoverButtonColor;
}

.DefaultButton:focus {
    background-color: $pressingButtonColor;
}

.DefaultButton:disabled {
    background-color: $buttonUnavailableColor;
}