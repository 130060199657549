@import './variables.scss';

//common felx div
.Row {
    display: flex;
    flex-direction: row;
}
.RowWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.Column {
    display: flex;
    flex-direction: column;
}
//default Page css
@mixin defaultPage {
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    padding: 140px 0;
    padding-left: 15%;
    padding-right: 15%;
    width: 70%;
    background: url('../../public/images/background.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.Page {
    @include defaultPage();
}
.Page:nth-child(odd) {
    background: none;
}

//starting page
.StartPage {
    @include defaultPage();
    justify-content: center;
    text-align: center;
    padding-top: 250px;
}
.StartPage h1 {
    margin-bottom: 0;
}

//ziele page
.ZielePage p{
    max-width: 700px;
}

//news page
.NewsPage {
    width: 100%;
}
.NewsArticle {
    background-color: white;
    height: 440px;
    cursor: pointer;
}
.NewsArticle img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.NewsArticleTextBlock {
    padding: 20px 20px;
}.NewsArticleTextBlock h3 {
    margin: 0;
    margin-bottom: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}.NewsArticleTextBlock p {
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}.NewsArticleListContainer {
    display: flex;
    flex-direction: row;
}.NewsPageArticle {
    background-color: white;
    width: auto;
    min-width: min-content;
    display: flex;
    margin-bottom: 50px;
    cursor: pointer;
}

//unternehmens page
.UnternehmenPage p{
    max-width: 1000px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.UnternehmenPage h2{
    text-align: center;
}
.UnternehmensContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    height: calc($companyBubbleSize + 2 * $companyBubbleMarginHeight);
}
.UnternehmensContainerShowMore {
    height: auto;
}
.UnternehmensContainerAll{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    height: auto;
}

//köpfe page
.KoepfePage {
    width: 100%;
}
.KoepfeContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    height: calc($avatarBubbleSize + 2 * $avatarBubbleMarginHeight + 60px);
}
.KoepfeContainerShowMore {
    height: auto;
}
.KoepfeContainerAll {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    height: auto;
}

//projekte page
.ProjektePage {
    width: 100%;
}
.ProjekteArticle {
    background-color: rgba(224, 224, 224, 0.952);
    cursor: pointer;
}
.ProjekteArticle img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}.ProjektTextBlock {
     color: black;
     text-decoration: none;
     padding: 20px 20px;
}
.ProjekteArticle h3 {
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow-y: hidden;
    text-overflow: ellipsis;
}
.ProjekteListContainer {
    display: flex;
    flex-direction: column;
}.ProjektePageArticle {
    background-color: white;
    width: auto;
    min-width: min-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 50px;
}.ProjektePageArticle h3 {
    margin-left: 30px;
}

     //sub pages
.SubPage {
    @include defaultPage();
    padding-top: 250px;
    padding-bottom: 250px;
}
.SubPage h2 {
    font-size: 36px;
    margin-bottom: 0;
}
.SubPage h3 {
    margin-bottom: 25px;
}
.SubPage ul{
    margin-bottom: 50px;
}
.SubPage ul li p {
    margin: 0;
}

    // form page
.FormPageContainer {
    margin: auto;
}
.FormPageContainer input, .FormPageContainer label {
    padding: 5px;
    margin-top: 5px;
}
.FormPageDirection {
    display: flex;
    flex-direction: row;
}
.FormPageTDleft {
    width: 50%;
    text-align: end;
    padding-right: 25px;
}
.FormPageTDright {
    width: 60%;
}
.FormPageSucces {
    color: limegreen;
    text-align: center;
}
.CenteredRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}.CenteredRow label{
     padding: 5px 20px 5px 5px;
 }
@media (max-width: 500px) {
    .FormPageDirection {
        display: flex;
        flex-direction: column;
    }
    .FormPageTDleft {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .FormPageTDright {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .FormPageTDright input {
        padding-top: 0;
        margin-top: 0;
    }
    .CenteredRow {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }.CenteredRow label{
        padding: 5px;
        text-align: center;
    }
}