@import './variables.scss';

.Container {
    display:flex;
    flex-direction: column;
    align-items: center;
    width: $avatarBubbleSize;
    padding: $avatarBubbleMarginHeight $avatarBubbleMarginWidth;
}
.AvatarContainer { 
    display:flex;
    justify-content: center;
    align-items: center;
    width: $avatarBubbleSize;
    height: $avatarBubbleSize;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-style: solid;
    border-width: 5px;
    border-radius: 50%;
    border-color: rgb(181, 181, 181);
    cursor: pointer;
}
.AvatarContainerLink {
    border-width: 0;
    border-radius: 50%;
    cursor: pointer;
}
.AvatarContainer img {
    max-width: 100%;
    border-radius: 50%;
    height: auto;
}
.AvatarContainerTextBox {
    text-align: center;
}
