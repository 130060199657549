@import './variables.scss';

//default Page css
.DetailPage {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: left;
  padding: 140px 0;
  padding-left: 15%;
  padding-right: 15%;
  width: 70%;
  background: url('../../public/images/background.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  flex-wrap: wrap;
}

.ContentContainer {
  width: 65%;
  display: flex;
  flex-direction: column;
  margin-right: 5%;
}

.SideContainer {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.SideContainer img {
  max-width: 100%;
  margin-right: 10px;
}

.SideContainer p{
  margin: 2px 0;
  font-size: 1em;
 }

.ContentContainer h3{
  margin: 2px 0;
}

.loading {
  display: block;
  min-height: 200px;
}

.CompanyDetailPersonImageContainer {
  width: 65%;
  border-radius: 50%;
}

.CompanySocialButtonContainer img {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

@media only screen and (max-width: $max-width-for-responsivnes) {
  .DetailPage {
    padding-top: 180px;
  }

  .ContentContainer {
    width: 100%;
  }

  .SideContainer {
    width: 100%;
  }
}
