@import './variables.scss';

@font-face {font-family: "Optima Bold";
    src: url("../fonts/OptimaBold/1290e6d413a94fef6bc9169dc4822d6a.eot"); /* IE9*/
    src: url("../fonts/OptimaBold/1290e6d413a94fef6bc9169dc4822d6a.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/OptimaBold/1290e6d413a94fef6bc9169dc4822d6a.woff2") format("woff2"), /* chrome、firefox */
    /* url("../fonts/OptimaBold/1290e6d413a94fef6bc9169dc4822d6a.woff") format("woff"),  chrome、firefox */
    url("../fonts/OptimaBold/1290e6d413a94fef6bc9169dc4822d6a.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    /* url("../fonts/OptimaBold/1290e6d413a94fef6bc9169dc4822d6a.svg#Optima Bold") format("svg");  iOS 4.1- */
}

@font-face {font-family: "Optima Regular";
    src: url("../fonts/OptimaRegular/3a861ecacbb23f33b2bb683e90c45e31.eot"); /* IE9*/
    src: url("../fonts/OptimaRegular/3a861ecacbb23f33b2bb683e90c45e31.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/OptimaRegular/3a861ecacbb23f33b2bb683e90c45e31.woff2") format("woff2"), /* chrome、firefox */
    /* url("../fonts/OptimaRegular/3a861ecacbb23f33b2bb683e90c45e31.woff") format("woff"),  chrome、firefox */
    url("../fonts/OptimaRegular/3a861ecacbb23f33b2bb683e90c45e31.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    /* url("../fonts/OptimaRegular/3a861ecacbb23f33b2bb683e90c45e31.svg#Optima nova LT Regular") format("svg");  iOS 4.1- */
}

@font-face {font-family: "Open Sans Semibold";
    src: url("../fonts/OpenSansSemiBold/33f225b8f5f7d6b34a0926f58f96c1e9.eot"); /* IE9*/
    src: url("../fonts/OpenSansSemiBold/33f225b8f5f7d6b34a0926f58f96c1e9.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/OpenSansSemiBold/33f225b8f5f7d6b34a0926f58f96c1e9.woff2") format("woff2"), /* chrome、firefox */
    url("../fonts/OpenSansSemiBold/33f225b8f5f7d6b34a0926f58f96c1e9.woff") format("woff"), /* chrome、firefox */
    url("../fonts/OpenSansSemiBold/33f225b8f5f7d6b34a0926f58f96c1e9.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/OpenSansSemiBold/33f225b8f5f7d6b34a0926f58f96c1e9.svg#Open Sans Semibold") format("svg"); /* iOS 4.1- */
}

@font-face {font-family: "Open Sans Regular";
    src: url("../fonts/OpenSansRegular/011860fc12a11332d2863a747de7bc32.eot"); /* IE9*/
    src: url("../fonts/OpenSansRegular/011860fc12a11332d2863a747de7bc32.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/OpenSansRegular/011860fc12a11332d2863a747de7bc32.woff2") format("woff2"), /* chrome、firefox */
    url("../fonts/OpenSansRegular/011860fc12a11332d2863a747de7bc32.woff") format("woff"), /* chrome、firefox */
    url("../fonts/OpenSansRegular/011860fc12a11332d2863a747de7bc32.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/OpenSansRegular/011860fc12a11332d2863a747de7bc32.svg#Open Sans Regular") format("svg"); /* iOS 4.1- */
}

h1 {
    font-family: 'Optima Bold';
    font-size: 70pt;
    line-height: 120%;
    letter-spacing: 0%;
    text-transform: uppercase;
}

h2 {
    font-family: 'Optima Bold';
    font-size: 54pt;
    line-height: 110%;
    letter-spacing: 0%;
    text-transform: uppercase;
    margin-top: 0;
}

h3 {
    font-family: 'Optima Regular';
    font-size: 22pt;
    line-height: 150%;
    letter-spacing: 0%;
}

p {
    font-family: 'Open Sans Regular';
    font-size: 16pt;
    line-height: 150%;
    letter-spacing: 0%;
}

a {
    font-family: 'Open Sans SemiBold';
    font-size: 16pt;
    line-height: 150%;
    letter-spacing: 0%;
    text-decoration: underline;
    color: #ae0e39;
}

.accent {
    font-family: 'Open Sans SemiBold';
    font-size: 16pt;
    line-height: 150%;
    letter-spacing: 0%;
}

.centerText {
    text-align: center;
}

@media only screen and (max-width: $max-width-for-responsivnes) {

    body { 
        word-wrap: break-word;
        word-break: break-word;
    }
    
    h1 {
        font-size: 2.375rem;
        line-height: 120%;
    }
    h2 {
        font-size: 2rem;
        line-height: 120%;
    }
    h3 {
        font-size: 1.375rem;
        line-height: 150%;
    }
    p {
        font-size: 1rem;
        line-height: 150%;
    }
    a {
        font-size: 1rem;
        line-height: 150%;
    }
    .accent {
        font-size: 1rem;
        line-height: 150%;
    }
}
