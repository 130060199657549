@import './variables.scss';

.HeaderContainer {
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

}
.marquee {
    background: $defaultButtonColor;
    color: antiquewhite;
    a {
        color: dodgerblue !important;
    }
}

.Container {
    margin: 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    align-items: center;

    .logo {
        img {
            height: 50px;
            justify-content: center;
            padding: 10px 30px;
            flex: 3;
        }
    }

    .list {
        display: flex;
        justify-content: center;
        color: whitesmoke;
        text-align: left;
        width: auto;
        height: auto;
        flex-wrap: wrap;
        font-size: 12px;
        flex: 7;

        .link {
            padding: 10px;
            text-decoration: none;
            color: black;
        }
        .link:visited {
            color: black;
        }
        .link:hover {
            color: black;
        }
    }
}

@media only screen and (max-width: $max-width-for-responsivnes) {
    .Container {
        flex-direction: column;
    }
}
